import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Palimax', // e.g: 'Name | Developer'
  lang: 'fr', // e.g: en, es, fr, jp
  description: 'Avec nos équipes performantes nous prenons toujours soin de nos clients 😎', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Bienvenue chez',
  name: 'Palimax',
  subtitle: 'Régalez-vous 🍟🍔🥤',
  cta: 'En Savoir Plus',
};

// ABOUT DATA
export const aboutData = {
  img: 'granville.jpeg',
  paragraphOne: 'Une holding Sympa',
  paragraphTwo: 'Des équipes performantes',
  paragraphThree: 'Un cadre de travail optimal',
  resume: 'https://www.google.com/maps/@48.8380814,-1.5925512,3a,84.8y,163.83h,90t/data=!3m6!1e1!3m4!1s4ZSI08Xe7nnTPaJSxxKVxw!2e0!7i16384!8i8192', // if no resume, the button will not show up
  link: 'Nous Sommes Ici 🚩',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Nous sommes là pour vous renseigner',
  btn: 'Mail',
  email: 'info@palimax.fr',
};

// FOOTER DATA


export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "Console Infomaniak",
      url: "https://login.infomaniak.com/fr/login",
      description:
        "Vous disposez d'une console d'administration ",
    },
    {
      id: nanoid(),
      name: "Support Infomaniak",
      url: "https://www.infomaniak.com/fr/support/faq/",
      description:
        "",
    }
      
  
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
